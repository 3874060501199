import ng from 'angular';
import { AcpAnalytics } from 'core';
import acpFamilyCardsOrderFlowContainer from './templates/acp-family-cards-create-flow.html';
import acpFamilyCardsIntroTemplate from './templates/acp-family-cards-intro.html';
import acpFamilyCardsOrder from './templates/acp-family-cards-order.html';

/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
export function acpFamilyCardsStates($stateProvider) {
  'ngInject';

  /* tslint:disable:object-literal-sort-keys */
  $stateProvider
    .state('family-cards', {
      parent: 'root',
      redirectTo: '.manage',
      url: '/family-cards',
      // Add resolve data as attributes: <acp-family-cards data="$resolve.fetchedData" ... />
      template: '<ui-view />',
      data: {
        gaLocation: 'family-cards',
        permissions: {
          // except: [],
          only: ['subaccount_enabled_account'],
          redirectTo: {
            subaccount_enabled_account: 'dashboard'
          }
        }
      },
      resolve: {
        module($ocLazyLoad) {
          'ngInject';

          return import(/* webpackChunkName: "acp.section.family-cards" */ './index').then(
            $ocLazyLoad.loadModule
          );
        },
        $componentName: /* @ngInject */ () => 'family-cards-intro',
        $subaccountDomainModel: /* @ngInject */ (
          module,
          acpSubaccountDomainModel
        ) => {
          return acpSubaccountDomainModel;
        }
      }
    })
    // Similar to the DDA Order Learn More Page, this should be the parent of the family subaccount order flow.
    .state('family-cards.create', {
      url: '/create',
      template: acpFamilyCardsIntroTemplate,
      data: {
        permissions: {
          except: 'account_has_subaccounts',
          redirectTo: {
            account_has_subaccounts: 'family-cards.manage'
          }
        }
      }
    })
    .state('family-cards.manage', {
      url: '/manage',
      resolve: {
        $subaccounts: /*@ngInject*/ (module, $subaccountDomainModel) => {
          return $subaccountDomainModel
            .list()
            .then(() => $subaccountDomainModel.getList());
        }
      },
      template:
        '<acp-family-cards-manage subaccounts="$resolve.$subaccounts"/>',
      data: {
        permissions: {
          only: 'account_has_subaccounts',
          redirectTo: {
            account_has_subaccounts: 'family-cards.create'
          }
        }
      }
    })
    .state('family-cards.transfer', {
      url: '/transfer',
      template: '<acp-family-cards-transfer/>',
      data: {
        permissions: {
          only: 'account_has_subaccounts'
        }
      }
    })
    .state('family-cards.error-state', {
      url: '/error-state',
      template: `<div id="acp-family-cards-error-state" layout="column" layout-align="center center">
                  <div layout="column" class="step-instructions" layout-padding>
                    <h3 cms-html="title:components/family-cards"></h3>
                    <div>
                      <p cms-html="instructions:components/family-cards"></p>
                    </div>
                  </div>
                  <div layout-align="center center">
                    <ns-button
                      ui-sref="dashboard"
                      cms-aria-label="go-to:components/family-cards" ns-type="raised" class="ns-positive">
                      <span cms-html="go-to:components/family-cards"></span>
                    </ns-button>
                  </div>
                </div>`
    })
    //
    // The Family Subaccount Order flow parent (normal page)
    //
    // This state has the steps count that determine what child pages in what order.
    // It also gates the entry to this order flow by requiring the `allow` param to be
    // passed when entering the state. If not, they get kicked back to the first step.
    .state(
      createStepFlowState(
        'family-cards.order',
        {
          url: '/order',
          resolve: {
            $orderModel: /*@ngInject*/ (module, acpFamilyCardsModel) => {
              return acpFamilyCardsModel();
            },
            $exitButtonText: /*@ngInject*/ (module, content) =>
              content
                .getKey('exit-order-flow', 'components/family-cards')
                .then((data) => data.copy),
            $user: /*@ngInject*/ (acpUserModel) => acpUserModel.get()
          }
        },
        [
          // The relative child state name. Pagination is false to control progress bar visibility
          { state: '.flow.choose-card', pagination: true },
          { state: '.flow.shipping-info', pagination: true },
          { state: '.flow.confirm', pagination: true },
          { state: '.completed' }
        ]
      )
    )
    .state('family-cards.order.flow', {
      template: acpFamilyCardsOrderFlowContainer,
      controller: /* @ngInject */ function ($scope, $stepManager) {
        $scope.$on('$stateChangeSuccess', function () {
          const currentStep = $stepManager.getCurrentStepIndex();
          if (currentStep >= 0) {
            $scope.currentStep = currentStep;
          }
        });
      }
    })
    .state('family-cards.order.flow.loading', {
      template:
        '<div class="loading"><ns-progress-circular class="md-primary" ns-mode="indeterminate"></ns-progress-circular></div>',
      params: {
        nextState: null,
        nextParams: null
      },
      controller: /*@ngInject*/ function ($state, $stateParams, $timeout) {
        // Immediately redirect and show loader until transition has finished
        $timeout(() => {
          $state.go($stateParams.nextState, $stateParams.nextParams, {
            location: 'replace'
          });
        });
      }
    })
    .state('family-cards.order.flow.choose-card', {
      url: '/choose-card',
      resolve: {
        $availableCards: /*@ngInject*/ (module, $orderModel) => {
          return $orderModel.getCards();
        }
      },
      template:
        '<acp-family-cards-choose-card available-cards="$resolve.$availableCards" nickname="$resolve.$orderModel.nickname" selected-card="$resolve.$orderModel.selectedCard" continue="$resolve.$next()" exit="$resolve.$exit()" exit-button-text="$resolve.$exitButtonText"/>'
    })
    .state('family-cards.order.flow.shipping-info', {
      url: '/shipping-info',
      template: `<acp-family-cards-shipping-info
        first-name="$resolve.$orderModel.firstName"
        middle-initial="$resolve.$orderModel.middleInitial"
        last-name="$resolve.$orderModel.lastName"
        addr-line-one="$resolve.$orderModel.addrLineOne"
        addr-line-two="$resolve.$orderModel.addrLineTwo"
        email="$resolve.$orderModel.email"
        zip="$resolve.$orderModel.zip"
        dob="$resolve.$orderModel.dob"
        continue="$resolve.$next()"
        exit="$resolve.$exit()"
        exit-button-text="$resolve.$exitButtonText"
        user="$resolve.$user"/>`
    })
    .state('family-cards.order.flow.confirm', {
      url: '/confirm',
      template: `<acp-family-cards-confirm
        nickname="$resolve.$orderModel.nickname"
        selected-card="$resolve.$orderModel.selectedCard"
        amount-loaded="$resolve.$orderModel.amountLoaded"
        first-name="$resolve.$orderModel.firstName"
        last-name="$resolve.$orderModel.lastName"
        addr-line-one="$resolve.$orderModel.addrLineOne"
        addr-line-two="$resolve.$orderModel.addrLineTwo"
        email="$resolve.$orderModel.email"
        zip="$resolve.$orderModel.zip"
        dob="$resolve.$orderModel.dob"
        continue="$resolve.$next()"
        exit="$resolve.$exit()"
        exit-button-text="$resolve.$exitButtonText"
        order-model="$resolve.$orderModel"/>`
    });
}

export function createStepFlowState(context, partial, steps) {
  /* @ngInject */
  function restartFlow($state, toParams) {
    return {
      state: $state.get('.flow.loading', context).name,
      params: ng.extend(
        {
          allow: true,
          nextState: $state.get(steps[0].state, context).name,
          nextParams: ng.extend({}, toParams, { allow: true })
        },
        toParams
      )
    };
  }

  return ng.extend(partial, {
    name: context,
    template: acpFamilyCardsOrder,
    redirectTo: /* @ngInject */ ($injector, toParams) =>
      $injector.invoke(restartFlow, null, { toParams }),
    controllerAs: 'vm',
    controller: /*@ngInject*/ familyCardsOrderCtrl,
    params: {
      allow: false // defaults to false
    },
    data: ng.extend(
      {
        permissions: {
          only: 'has_valid_allow_parameter',
          redirectTo: {
            has_valid_allow_parameter: /* @ngInject */ (
              $injector,
              transitionProperties
            ) => $injector.invoke(restartFlow, null, transitionProperties)
          }
        }
      },
      partial.data
    ),
    resolve: ng.extend(
      {
        $stateContext: /*@ngInject*/ () => context,
        $steps: /*@ngInject*/ ($stepManager) => $stepManager.getAllSteps(),
        // Can be overriden by parent
        $stepManagerLocals: ng.noop,
        $stepManager: /*@ngInject*/ (
          module,
          acpStepStateManager,
          $stateContext,
          $stepManagerLocals
        ) => acpStepStateManager(steps, $stateContext, $stepManagerLocals),
        $$goToStep: /*@ngInject*/ ($state, $stateContext, $stateParams) => (
          step
        ) => {
          if (step) {
            $state.go(
              '.flow.loading',
              ng.extend(
                {
                  nextState: step.name,
                  nextParams: ng.copy($stateParams)
                },
                $stateParams
              ),
              {
                relative: $stateContext
              }
            );
          }
        },
        $exit: /*@ngInject*/ (
          $state,
          acpAnalytics: AcpAnalytics,
          acpUpgradeLoggingModel
        ) => () => {
          const exitStep = $state.current.url;
          // We don't care about logging exits if the user is in an error state.
          if (exitStep.indexOf('errors') === -1) {
            acpUpgradeLoggingModel().logFlowCancelled({
              stepName: exitStep.replace(/\//g, ''),
              flowName: context
            });
          }
          acpAnalytics.sendEvent(
            'Family-Cards',
            'createSubAccountExit',
            exitStep,
            true
          );
          $state.go('dashboard', null, { reload: 'dashboard' });
        },
        $previous: /*@ngInject*/ ($window) => () => {
          // Because we enforce starting at step 1, we can be sure that we have a good previous state to go to.
          // We cannot $state.go() anywhere as it will *add* a new history stack entry instead of popping
          $window.history.back();
        },
        $next: /*@ngInject*/ ($$goToStep, $stepManager) => () => {
          $$goToStep($stepManager.getStepState(1));
        }
      },
      partial.resolve
    )
  });
  function familyCardsOrderCtrl($state) {
    Object.defineProperty(this, 'stateData', {
      get: () => $state.current.data
    });
  }
}

export default acpFamilyCardsStates;
